import 'zone.js/plugins/zone-error';

export const environment = {
  production: false,
  local: false,
  baseURL: '/rest',
  contactEmail: 'contato@viajabox.com',
  marketingEmail: 'marketing@viajabox.com',
  google: {
    gtag: 'GTM-MH7VVWZ',
    analytics: 'G-Z04F5QZ3JF',
    login: '840902889731-qo4i22ei60tnv1m8cmt8kin1h9leltnh.apps.googleusercontent.com'
  },
  facebook: '384862779252834',
  paypalId: 'AaWOlNm_bw8giPlXO1W7q8DvJPOJ974pJgtvCECDCTtbl9s-VegWSwfF0eQCKDe8uB97JpVarXgg2XsH',
  paypalCurrency: 'USD'
};
